import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NewPortalAnnouncementComponent} from './new-portal-announcement/new-portal-announcement.component';
import {DineClubComponent} from './dine-club/dine-club.component';
import {SharedModule} from 'src/app/shared/shared.module';
import {CoreModule} from '../../core/core.module';
import {BrokenEnrollKbaIntroComponent} from './broken-enroll/components/welcome/broken-enroll-kba-intro.component';
import {RegisterComponent} from './broken-enroll/components/register/register.component';
import {DashboardModule} from '../dashboard/dashboard.module';
import {ThinFileComponent} from './broken-enroll/components/thin-file/thin-file.component';
import {PartnerWebPostComponent} from './partner-web-post/partner-web-post.component';
import {RouterModule} from '@angular/router';
import {DarkWebComponent} from './dark-web/dark-web.component';
import {FastPerksComponent} from './fast-perks/fast-perks.component';
import { HowToComponent } from './intro/how-to/how-to.component';
import { DarkWebOnetimeAdComponent } from './dark-web-onetime-ad/dark-web-onetime-ad.component';
import {BrandImageDirective} from 'src/app/shared/directives/brand-image.directive';

@NgModule({
  declarations: [
    NewPortalAnnouncementComponent,
    DineClubComponent,
    BrokenEnrollKbaIntroComponent,
    RegisterComponent,
    ThinFileComponent,
    PartnerWebPostComponent,
    DarkWebComponent,
    FastPerksComponent,
    HowToComponent,
    DarkWebOnetimeAdComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardModule,
    RouterModule,
    BrandImageDirective,
  ],
})
export class IaoModule { }
