import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {routes} from 'src/app/app-routes';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import * as Sentry from '@sentry/angular';
import {TraceModule} from '@sentry/angular';
import {environment} from '../environments/environment';
import {provideRouter, Router, RouterOutlet} from '@angular/router';
import {NgxsModule} from '@ngxs/store';
import {SessionState} from './shared/state/session/session.state';
import {DarkwebState} from './modules/dark-web/state/darkweb.state';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {CtaState} from 'src/app/shared/state/cta/cta.state';
import {CreditReportState} from 'src/app/shared/state/credit-report/credit-report-state.service';
import {EventState} from 'src/app/shared/state/calendly/events.state';
import {CommonModule} from '@angular/common';
import {IaoModule} from 'src/app/modules/iao-chain/iao.module';
import {appErrorFactory, AppErrorHandler} from 'src/app/shared/errors/app-error-handler.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    NgbModule,
    NgbModalModule,
    CoreModule,
    IaoModule,
    HammerModule,
    TraceModule,

    NgxsModule.forRoot([
      SessionState, DarkwebState, CtaState, CreditReportState, EventState,
    ], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      keys: ['session.token'],
    }),
    RouterOutlet,
    // Load Dev Only Modules
    // !environment.production
    //   ? [NgxsLoggerPluginModule.forRoot(),
    //     NgxsReduxDevtoolsPluginModule.forRoot()]
    //   : [],
  ],
  providers: [
    provideRouter(routes),
    // Use our custom AppErrorHandler to process Angular errors
    {provide: ErrorHandler, useFactory: appErrorFactory},
    // Alias AppErrorHandler to the ErrorHandler token so we can inject AppErrorHandler and configure it
    {provide: AppErrorHandler, useExisting: ErrorHandler},
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  exports: [
    BrowserAnimationsModule,
    CoreModule,
    FormsModule,
    NgbModule,
    NgbModalModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
