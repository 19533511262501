import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {IAccount} from 'src/app/shared/models/account';
import {AccountService} from 'src/app/shared/services/account.service';


/**
 * If the account is set to force password reset, this will force the user into that page.
 */
export const ForcePasswordResetGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const accountService = inject(AccountService);

  // Maybe change this to not allow this route if they aren't forced to reset their password?
  if (router.url === '/auth/reset-password') {
    return true;
  }

  return new Observable(sub => {
    // Check the account settings to see if forcePasswordReset is still set
    // NOTE: this in theory should not complete guard until we have an account object.  If selectAccount$
    // subscribes and gets a null account object, this guard will not complete it's observable.  If later
    // selectAccount$ pushes an account object, we will check its settings and complete.
    accountService.getAccount()
      .subscribe((account: IAccount) => {
        let canPass = !account.settings.forcePasswordReset;

        // If we need to reset the password, redirect to that page and reject the observable
        if (account.settings.forcePasswordReset) {
          // Same login in AccountService.hasScopeAccess()
          const hasChangePasswordAccess = account.user.access_scopes.indexOf('change-password') > -1;
          const hasAllAccess = account.user.access_scopes.indexOf('*') > -1;

          if (hasChangePasswordAccess || hasAllAccess) {
            router.navigateByUrl('/auth/reset-password');
          } else {
            // If they don't have access to update their password, we have to let them pass.
            canPass = true;
          }
        }

        sub.next(canPass);
        sub.complete();
      });
  });
}
