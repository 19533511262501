import {inject} from '@angular/core';
import {CanMatchFn, Route, UrlSegment} from '@angular/router';
import {first} from 'rxjs';
import {CreditReportService} from '../services/credit-report.service';
import {map} from 'rxjs/operators';

/**
 * This should return true for active accounts, and false for manual verify & thin file accounts
 */
export const HasCreditReportGuard: CanMatchFn = (
  route: Route,
  segments: UrlSegment[],
) => {
  const creditReportService = inject(CreditReportService);

  return creditReportService
    .get()
    .pipe(
      first(),
      map(report => !report.isEmpty()),
    );
}
